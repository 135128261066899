<template>
  <div class="account" style="background: #fff">
    <top-fixed></top-fixed>
    <div class="conter_box">
      <div style="display: flex; justify-content: space-between">
        <div style="color: #000000; font-size: 0.6rem">
          {{ data.act_name }}
        </div>
        <div style="color: #fa9d45; font-size: 0.6rem">
          {{ data.progress_name }}·{{ data.status_name }}
        </div>
      </div>
      <!-- <div style="color: #cccccc; font-size: 0.4rem; margin: 0.4rem 0">
        收到有效时间1天
      </div> -->
      <div
        style="
          margin: 0.6rem 0;
          background: #f4f4f4;
          border: 0.5px solid #dddddd;
          border-radius: 9px;
          box-shadow: 0px 1.5px 3px 0px rgba(0, 0, 0, 0.08) inset;
          display: flex;
          justify-content: space-between;
          padding: 0.8rem 0.6rem;
        "
      >
        <div>
          <div
            style="
              color: #000000;
              font-weight: 600;
              font-size: 0.7rem;
              margin-bottom: 0.5rem;
              text-align: center;
            "
          >
            {{ data.min_balance }}
          </div>
          <div style="text-align: center">
            <img
              src="../assets/img2/170.png"
              style="
                vertical-align: middle;
                width: 0.5rem;
                margin-right: 0.1rem;
              "
            />
            <span style="font-size: 0.5rem; vertical-align: middle">
              {{ $t("hj50") }}(USDT)</span
            >
          </div>
        </div>

        <div>
          <div
            style="
              color: #000000;
              font-weight: 600;
              font-size: 0.7rem;
              margin-bottom: 0.5rem;
              text-align: center;
            "
          >
            {{ data.end_time.substring(0, 10) }}
          </div>
          <div style="text-align: center">
            <img
              src="../assets/img2/171.png"
              style="
                vertical-align: middle;
                width: 0.5rem;
                margin-right: 0.1rem;
              "
            />
            <span style="font-size: 0.5rem; vertical-align: middle">
              {{ $t("hj32") }}</span
            >
          </div>
        </div>

        <div>
          <div
            style="
              color: #000000;
              font-weight: 600;
              font-size: 0.7rem;
              margin-bottom: 0.5rem;
              text-align: center;
            "
          >
            {{ data.awards }}
          </div>
          <div style="text-align: center">
            <img
              src="../assets/img2/172.png"
              style="
                vertical-align: middle;
                width: 0.5rem;
                margin-right: 0.1rem;
              "
            />
            <span style="font-size: 0.5rem; vertical-align: middle">
              {{ $t("hj30") }}(ETH)</span
            >
          </div>
        </div>
      </div>
    </div>

    <div
      style="
        margin: 1rem 0.7rem;
        border-bottom: 0.5px dashed #fa9d45;
        padding-bottom: 1rem;
      "
    >
      <span
        style="
          color: #000000;
          font-size: 0.7rem;
          font-weight: 600;
          vertical-align: middle;
          margin-right: 0.3rem;
        "
      >
        {{ $t("hj35") }}</span
      >
      <img
        src="../assets/img2/sm.png"
        style="
          vertical-align: middle;
          width: 0.7rem;
          margin-right: 0.3rem;
          vertical-align: middle;
        "
      />
    </div>

    <div
      style="
        margin: 0 0.8rem;
        padding: 0 0 1rem;
        font-size: 0.6rem;
        line-height: 0.8rem;
        border-bottom: 0.5px dashed rgb(250, 157, 69);
      "
    >
      {{ data.act_rule }}
    </div>

    <div
      style="
        color: #000000;
        text-align: center;
        margin-top: 1rem;
        font-size: 0.5rem;
        font-weight: 600;
      "
    >
      {{ $t("hj49") }}
    </div>

    <!-- <div class="but">申请奖励 0.021000ETH</div> -->
  </div>
</template>
  
  
  <script>
import TopFixed from "../components/TopFixed.vue";
import { act_detail } from '../http/api'
export default {
  name: "AccountView",
  data () {
    return {
      data: ""
    };
  },
  components: { TopFixed },
  methods: {
    dataInit () {
      act_detail(this.$route.query.id).then((res) => {
        this.data = res.data
      })
    },
  },
  created () {
    this.dataInit()
  }
};
</script>
  <style  scoped lang="scss">
.center {
  text-align: center;
}
.conter_box {
  background: #ffffff;
  border-radius: 16px;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);
  margin: 0.8rem;
  padding: 0.5rem;
}
.but {
  background-image: linear-gradient(180deg, #fffce9, #ffc782 73%, #ffb871);
  border: 0.5px solid #fa9d45;
  border-radius: 50px;
  line-height: 1.4rem;
  width: 80%;
  box-shadow: 0px 1.5px 3px 0px #ffd7b2 inset;
  text-align: center;
  position: absolute;
  bottom: 0;
  transform: translate(13%, -100%);
  font-size: 0.6rem;
  font-weight: 600;
}
</style>